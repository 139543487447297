import { FileTextOutlined, FolderOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Space, Tag, Typography } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import TableComponent from "../TableComponent";
import { SelectTKG } from "tkg-composite-ui";
import { getListClients } from "../../../time-sheet/service";
import RejectModal from "./modal/RejectModal";
import DetailRequestModal from "./modal/DetailRequestModal";
import UpdateRequestModal from "./modal/UpdateRequestModal";
import dayjs from "dayjs";
import {
  deleteTimeoffRequest,
  responseRequest,
  updateTimeoffRequest,
} from "../../service";
import Swal from "sweetalert2";
import {
  TIME_OFF_MODE,
  TIME_OFF_POLICY,
  TIME_OFF_STATUS,
} from "../../../../utils/constants";
import { compareFn } from "../../../../utils/functions";
import moment from "moment";

const getMonthsBetweenTwoDates = (start_time, end_time) => {
  const startDate = moment(start_time, "YYYY-MM-DD");
  const endDate = moment(end_time, "YYYY-MM-DD").endOf("month");

  const months = [];
  const flag = startDate;
  while (startDate.isBefore(endDate)) {
    months.push(flag.format("MMMM"));
    flag.add(1, "month");
  }

  return months;
};

export function time_convert(num) {
  // Calculate the number of hours by dividing num by 60 and rounding down
  var hours = Math.floor((num) / 60);

  // Calculate the remaining minutes by taking the remainder when dividing num by 60
  var minutes = (num) % 60;

  // Return the result as a string in the format "hours:minutes"
  return hours + "h" + minutes + "m";
}
const getYearsBack = (back) => {
  const year = new Date().getFullYear() + 2;
  return Array.from({length: back}, (v, i) => year - back + i + 1);
}
const RequestTab = (props) => {
  const {
    loading,
    setLoading,
    dataTimeoff,
    fetchListDataTimeoff,
    listFilePath,
    setListFilePath,
    timeOffMode,
    setTimeOffMode,
    paidValue,
    setPaidValue,
  } = props;
  const user = JSON.parse(sessionStorage.getItem("user"));

  const [form] = Form.useForm();
  const [openModal, setOpenModal] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openUpdateRequest, setOpenUpdateRequest] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [filterOption, setFilterOption] = useState({
    month: moment().format("MMMM"),
    year: moment().format('YYYY'),
    status: TIME_OFF_STATUS.ALL,
    policy: TIME_OFF_POLICY.ALL,
  });


  const [listUser, setListUser] = useState([]);

  const fetchListUser = async () => {
    let res = await getListClients().then((res) => {
      return res.data.response;
    });
    setListUser(res);
    return res;
  };

  useEffect(() => {
    fetchListUser();
  }, []);
  useEffect(() => {
    fetchListDataTimeoff();
  }, [fetchListDataTimeoff]);

  const getListData = useMemo(() => {
    let dataSearch = dataTimeoff;
    if (filterOption.month) {
      dataSearch = dataSearch?.filter((item) => {
        return getMonthsBetweenTwoDates(
          item?.start_time,
          item?.end_time
        ).includes(filterOption?.month);
      });
    }
    if (filterOption.year) {
      dataSearch = dataSearch?.filter((item) => {
        return [moment(item?.start_time).format("YYYY"), moment(item?.end_time).format("YYYY")].includes(filterOption?.year);
      });
    }

    if (filterOption.status && filterOption.status !== TIME_OFF_STATUS.ALL) {
      dataSearch = dataSearch?.filter(
        (item) => item?.status === filterOption?.status
      );
    }
    if (filterOption.policy && filterOption.policy !== TIME_OFF_POLICY.ALL) {
      dataSearch = dataSearch?.filter(
        (item) => item?.policy === filterOption?.policy
      );
    }

    //Current Page slice
    return dataSearch;
  }, [dataTimeoff, filterOption]);

  const handleOpenDetail = (record) => {
    setDetailData(record);
    setOpenModal(true);
  };
  const handleClose = () => setOpenModal(false);
  const handleOpenReject = () => setOpenRejectModal(true);
  const handleCancelReject = () => setOpenRejectModal(false);
  const handleConfirmReject = (values) => {
    const payload = {
      status: TIME_OFF_STATUS.REJECTED,
      ...values,
    };
    setLoading(true);
    responseRequest(payload, detailData.id).then((res) => {
      setOpenModal(false);
      fetchListDataTimeoff();
      setLoading(false);
    });
    setOpenRejectModal(false);
    form.resetFields();
  };

  const handleApprove = () => {
    const payload = {
      status: TIME_OFF_STATUS.APPROVED,
    };
    setLoading(true);
    responseRequest(payload, detailData.id).then((res) => {
      setOpenModal(false);
      fetchListDataTimeoff();
      setLoading(false);
    });
  };
  const onSelectMonthChange = (value) => {
    setFilterOption((prev) => ({ ...prev, month: value }));
  };
  const onStatusChange = (value) => {
    setFilterOption((prev) => ({ ...prev, status: value }));
  };
  const onPolicyChange = (value) => {
    setFilterOption((prev) => ({ ...prev, policy: value }));
  };

  const handleOpenUpdateRequest = (record) => {
    setDetailData(record);
    setOpenUpdateRequest(true);
  };
  const handleCancelUpdate = () => {
    setOpenUpdateRequest(false);
    setDetailData({});
  };
  const handleUpdateRequest = (values) => {
    const payload = {
      ...values,
      start_time:
        timeOffMode === TIME_OFF_MODE.ONE_DAY
          ? values.start_time.format("YYYY-MM-DD")
          : values.period[0].format("YYYY-MM-DD HH:mm:ss"),
      end_time:
        timeOffMode === TIME_OFF_MODE.ONE_DAY
          ? values.start_time.format("YYYY-MM-DD")
          : values.period[1].format("YYYY-MM-DD HH:mm:ss"),
      documents: listFilePath,
      employeeId: selectedEmployee,
    };

    console.log(payload);
    setLoading(true);
    updateTimeoffRequest(payload, detailData.id).then((res) => {
      setOpenUpdateRequest(false);
      fetchListDataTimeoff();
      setLoading(false);
      setListFilePath([]);
      setPaidValue();
      form.resetFields();
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
      });
    });
  };

  const handleDeleteRequest = (record) => {
    Swal.fire({
      title: "Are you sure to delete this request?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setLoading(true);

        deleteTimeoffRequest(record.id).then((res) => {
          fetchListDataTimeoff();
          setLoading(false);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
        });

        // fetchactivityData();
      }
    });
  };

  const countDay = (start, end) => {
    let startNow = new Date(start);
    let count = 0;
    let diff = dayjs(end).diff(dayjs(start), "day") + 1;
    for (let i = 0; i < diff; i++) {
      const newStart = startNow.setDate(new Date(start).getDate() + i);
      const lastCount = new Date(newStart).getDay();
      if (Number(lastCount) !== 0 && Number(lastCount) !== 6) {
        count = count + 1;
      }
    }
    return count;
  };

  const columnsManagerView = [
    {
      title: "Employee",
      dataIndex: "employee",
      key: "employee",
      width: "150px",
      render: (_, record) => (
        <Tag>
          {record.user.first_name} {record.user.surname}
        </Tag>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "250px",
      render: (_, record) => record.title,
    },
    {
      title: "PERIOD",
      dataIndex: "period",
      key: "period",
      width: "200px",
      render: (_, record) => {
        let type = "";

        if (
          dayjs(record.end_time).diff(dayjs(record.start_time), "minutes") /
            60 ===
          0
        ) {
          type = "OneDay";
        } else if (
          0 <
            dayjs(record.end_time).diff(dayjs(record.start_time), "minutes") /
              60 &&
          dayjs(record.end_time).diff(dayjs(record.start_time), "minutes") /
            60 <
            12
        ) {
          type = "Custom";
        } else {
          type = "MultiDay";
        }

        switch (type) {
          case "OneDay":
            return (
              <span>{dayjs(record.start_time).format("DD-MMM-YYYY")}</span>
            );
          case "Custom":
            return (
              <>
                <div>{`${dayjs(record.start_time).format(
                  "DD-MMM-YYYY"
                )} `}</div>
                <div>
                  {`${dayjs(record.start_time).format("HH:mm A")} -> ${dayjs(
                    record.end_time
                  ).format("HH:mm A")}`}
                </div>
              </>
            );
          case "MultiDay":
            return (
              <span>{`${dayjs(record.start_time).format(
                "DD-MMM-YYYY"
              )} -> ${dayjs(record.end_time).format("DD-MMM-YYYY")}`}</span>
            );

          default:
            return "";
        }
      },
    },
    {
      title: "REQUESTED",
      dataIndex: "requested",
      key: "requested",
      width: "120px",
      render: (_, record) => {
        let type = "";

        if (
          dayjs(record.end_time).diff(dayjs(record.start_time), "minutes") /
            60 ===
          0
        ) {
          type = "OneDay";
        } else if (
          0 <
            dayjs(record.end_time).diff(dayjs(record.start_time), "minutes") /
              60 &&
          dayjs(record.end_time).diff(dayjs(record.start_time), "minutes") /
            60 <
            12
        ) {
          type = "Custom";
        } else {
          type = "MultiDay";
        }

        switch (type) {
          case "OneDay":
            return (
              <span>{countDay(record.start_time, record.end_time)} days</span>
            );
          case "Custom":
            return (
              <span>
                {time_convert(dayjs(record.end_time).diff(dayjs(record.start_time), "minutes"))}
              </span>
            );
          case "MultiDay":
            return (
              <span>{countDay(record.start_time, record.end_time)} days</span>
            );

          default:
            return "";
        }
      },
    },
    {
      title: "POLICY",
      dataIndex: "policy",
      key: "policy",
      width: "120px",
    },
    {
      title: "STATUS",
      dataIndex: "Status",
      key: "Status",
      width: "80px",
      render: (_, record) => {
        let color;
        let currentStatus;
        if (record.status === TIME_OFF_STATUS.PENDING) {
          color = "warning";
          currentStatus = "Pending";
        } else if (record.status === TIME_OFF_STATUS.APPROVED) {
          color = "success";
          currentStatus = "Accepted";
        } else {
          color = "error";
          currentStatus = "Rejected";
        }

        return (
          <Tag color={color} key={record.status}>
            {currentStatus}
          </Tag>
        );
      },
    },
    {
      title: "CREATED",
      dataIndex: "Created",
      key: "Created",
      width: "200px",
      render: (_, record) => {
        return `${
          record.created_by_assign?.first_name ?? record.user?.first_name
        } ${
          record.created_by_assign?.surname ?? record.user?.surname
        } @ ${moment(record.created_at).format("DD-MMM-YYYY")}`;
      },
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      align: "center",
      width: "100px",
      render: (_, record) => (
        <Space>
          <Button
            type="primary"
            className="btnTKGAction"
            icon={<FolderOutlined />}
            ghost
            onClick={() => handleOpenDetail(record)}
          >
            View
          </Button>
          {record.user_id === user.id && (
            <>
              <Button
                type="primary"
                className="btnTKGAction"
                ghost
                onClick={() => handleOpenUpdateRequest(record)}
                disabled={
                  record.status !== TIME_OFF_STATUS.PENDING ||
                  record.user_id !== user.id
                }
              >
                Edit
              </Button>
              <Button
                type="primary"
                className="btnTKGDeny"
                ghost
                onClick={() => handleDeleteRequest(record)}
                disabled={
                  record.status !== TIME_OFF_STATUS.PENDING ||
                  record.user_id !== user.id
                }
              >
                Delete
              </Button>
            </>
          )}
        </Space>
      ),
    },
  ];
  // const columnsEmployeeView = [
  //   {
  //     title: "PERIOD",
  //     dataIndex: "period",
  //     key: "period",
  //     width: "200px",
  //     render: (_, record) => (
  //       <span>
  //         {dayjs(record.start_time).format("DD-MMM-YYYY")} {" -> "}
  //         {dayjs(record.end_time).format("DD-MMM-YYYY")}
  //       </span>
  //     ),
  //   },
  //   {
  //     title: "REQUESTED",
  //     dataIndex: "requested",
  //     key: "requested",
  //     width: "120px",
  //     render: (_, record) => (
  //       <span>
  //         {countDay(record.start_time, record.end_time)}{" "}
  //         days
  //       </span>
  //     ),
  //   },
  //   {
  //     title: "POLICY",
  //     dataIndex: "policy",
  //     key: "policy",
  //     width: "120px",
  //   },
  //   {
  //     title: "STATUS",
  //     dataIndex: "Status",
  //     key: "Status",
  //     width: "80px",
  //     render: (_, record) => {
  //       let color;
  //       let currentStatus;
  //       if (record.status === TIME_OFF_STATUS.PENDING) {
  //         color = "warning";
  //         currentStatus = "Pending";
  //       } else if (record.status === TIME_OFF_STATUS.APPROVED) {
  //         color = "success";
  //         currentStatus = "Accepted";
  //       } else {
  //         color = "error";
  //         currentStatus = "Rejected";
  //       }

  //       return (
  //         <Tag color={color} key={record.status}>
  //           {currentStatus}
  //         </Tag>
  //       );
  //     },
  //   },
  //   {
  //     title: "Action",
  //     dataIndex: "Action",
  //     key: "Action",
  //     align: "center",
  //     width: "200px",
  //     render: (_, record) => (
  //       <Space>
  //         <Button
  //           type="primary"
  //           className="btnTKGAction"
  //           icon={<FolderOutlined />}
  //           ghost
  //           onClick={() => handleOpenDetail(record)}
  //         >
  //           View
  //         </Button>{" "}
  //         <Button
  //           type="primary"
  //           className="btnTKGAction"
  //           ghost
  //           onClick={() => handleOpenUpdateRequest(record)}
  //           disabled={record.status !== TIME_OFF_STATUS.PENDING}
  //         >
  //           Edit
  //         </Button>
  //         <Button
  //           type="primary"
  //           className="btnTKGDeny"
  //           ghost
  //           onClick={() => handleDeleteRequest(record)}
  //           disabled={record.status !== TIME_OFF_STATUS.PENDING}
  //         >
  //           Delete
  //         </Button>
  //       </Space>
  //     ),
  //   },
  // ];
  return (
    <StyledContainer>
      <StyledHeader>
        <Form>
          <Row gutter={[8, 4]} layout="vertical ">
            <Col xl={4} lg={6} md={6} sm={8} xs={8}>
              <label className="form-label">Month: </label>
              <Form.Item name="Month">
                <SelectTKG
                  form={form}
                  onChange={onSelectMonthChange}
                  allowClear
                  // placeholder="Select tags"
                  defaultValue={moment().format("MMMM")}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={moment.months().map((item) => ({
                    label: item,
                    value: item,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col xl={4} lg={6} md={6} sm={8} xs={8}>
              <label className="form-label">Year: </label>
              <Form.Item name="Year">
                <SelectTKG
                  form={form}
                  onChange={(value) => setFilterOption((prev) => ({ ...prev, year: String(value) }))}
                  allowClear
                  // placeholder="Select tags"
                  defaultValue={moment().format("YYYY")}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={getYearsBack(20).sort((a, b) => b-a).map((item) => ({
                    label: item,
                    value: item,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col xl={4} lg={6} md={6} sm={8} xs={8}>
              <label className="form-label">Status: </label>
              <Form.Item name="Status">
                <SelectTKG
                  form={form}
                  onChange={onStatusChange}
                  allowClear
                  // placeholder="Select tags"
                  defaultValue={"Show All"}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      label: "Show All",
                      value: "",
                    },
                    {
                      label: "Show Pending",
                      value: 1,
                    },
                    {
                      label: "Show Approve",
                      value: 2,
                    },
                    {
                      label: "Show Reject",
                      value: 3,
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xl={4} lg={6} md={6} sm={8} xs={8}>
              <label className="form-label">Policy: </label>
              <Form.Item name="Policy">
                <SelectTKG
                  form={form}
                  onChange={onPolicyChange}
                  allowClear
                  // placeholder="Select tags"
                  defaultValue={"Show All"}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      label: "Show All",
                      value: "",
                    },
                    {
                      value: "Annual Leave",
                      label: "Annual Leave",
                    },
                    {
                      value: "Sick Leave",
                      label: "Sick Leave",
                    },
                    {
                      value: "Carer's Leave",
                      label: "Carer's Leave",
                    },
                    {
                      value: "Unpaid",
                      label: "Unpaid",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <TableComponent
          dataSource={getListData.sort(compareFn)}
          scroll={{ x: 1000 }}
          columns={columnsManagerView}
          header="Requests"
          pagination={true}
        />
      </StyledHeader>
      {Object.keys(detailData).length > 0 && (
        <DetailRequestModal
          openModal={openModal}
          handleClose={handleClose}
          detailData={detailData}
          handleOpenReject={handleOpenReject}
          handleApprove={handleApprove}
        />
      )}
      <RejectModal
        isModalOpen={openRejectModal}
        handleConfirm={handleConfirmReject}
        handleCancel={handleCancelReject}
        form={form}
      />

      {/* For employee */}
      {Object.keys(detailData).length > 0 && (
        <UpdateRequestModal
          loading={loading}
          setLoading={setLoading}
          isModalOpen={openUpdateRequest}
          setListFilePath={setListFilePath}
          handleCancel={handleCancelUpdate}
          handleUpdateRequest={handleUpdateRequest}
          detailData={detailData}
          form={form}
          timeOffMode={timeOffMode}
          setTimeOffMode={setTimeOffMode}
          paidValue={paidValue}
          setPaidValue={setPaidValue}
          listUser={listUser}
          selectedEmployee={selectedEmployee}
          setSelectedEmployee={setSelectedEmployee}
        />
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  background: #fff;
`;

const StyledHeader = styled.div`
  padding: 10px;
  .form-label {
    font-weight: 700 !important;
  }
`;

export default RequestTab;
